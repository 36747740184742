import React from 'react';
import { graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Hero } from '../components/Hero/';
import { Badges } from '../components/Badges/';
import { Text } from '../styles/404';

const NotFoundPage = ({ data: { Postbote } }) => (
  <PageLayout>
    <SEO title="Seite nicht gefunden" />
    <Hero
      image={Postbote}
      title="Hast du dich verirrt?"
      subTitle="Diese Seite existiert nicht"
      fullscreen>
      <Text>Jetzt die DropFriends-App downloaden.</Text>
      <Badges />
    </Hero>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default NotFoundPage;
